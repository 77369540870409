import { mapActions } from "vuex";

export default {
    data: () => ({
        message: '',
        error: false,
        loading: false,
        email: '',
        token: '',
        password: '',
        password_confirmation: '',
        errors: {}
    }),
    methods: {
        ...mapActions('auth', ['ActionSendConfirmResetPassword']),
        login() {
            this.$router.push({ name: 'login' });
        },
        send() {
            this.loading = true
            this.error = false
            this.errors = {}
            this.message = ''

            const payload = {
                email: this.email,
                token: this.token,
                password: this.password,
                password_confirmation: this.password_confirmation
            }

            this.ActionSendConfirmResetPassword(payload)
                .then((r) => {
                    this.message = r.message
                    setTimeout(() => {  
                        this.$router.push({ name: 'login' });
                     }, 2000);
                })
                .catch((e) => {
                    this.message = e.message
                    this.error = true
                    this.errors = e.errors
                })
                .finally(()=>{
                    this.loading = false
                })
        }
    },
    created(){
        this.email = this.$route.params.email
        this.token = this.$route.params.token
    }
}